<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="spinner"
    />
    <v-layout wrap justify-center>
      <v-flex xs12 lg11 pt-lg-6>
        <v-card
          class="mx-auto elevation-2 pa-6"
          align="center"
          outlined
        >
        <!----------------------filters---------------------------->
        <v-layout wrap>
         <v-flex xs9 text-left>
            <span style="font-size:20px" class="nsbold">
                Feedback Report
            </span>
         </v-flex>
          <v-flex xs3 text-right>
            <v-text-field
              v-model="keyword"
              dense
              hide-details
              label="Search : Ad.no/Name"
              outlined
              clearable
            ></v-text-field>
          </v-flex>
         
         <!--------------------------list------------------------>
        </v-layout>
          <v-layout wrap justify-center>
            <v-flex xs12 v-if="feedbackData">
              <v-layout wrap py-md-2 class="nsbold" style="font-size: 16px">
                <v-flex xs12 pa-md-2 v-if="feedbackData.length > 0">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">No.</th>
                          <th class="text-left">Admission No</th>
                          <th class="text-left">Name</th>
                          <th class="text-left">Class</th>
                          <th class="text-left">Date</th>
                          <th class="text-left">View</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, i) in feedbackData" :key="i">
                          <td>
                            <span v-if="page == 1">
                              {{ i + 1 }}
                            </span>
                            <span v-else>
                              {{ i + 1 + 20 * (page - 1) }}
                            </span>
                          </td>
                          <td>
                            {{ item.admissionNo }}
                          </td>
                          <td>
                            <router-link
                              style="text-decoration: none; color: black"
                              :to="'/studentfeedbackData?id=' + item.studentid._id"
                            >
                            {{ item.name }}
                            </router-link>
                            </td>
                          <td>
                            <span v-if="item.studentid">
                              {{ item.studentid.studentclass.className }}
                            </span>
                            <span v-else>NA</span>
                          </td>
                          
                          <td>
                            <span v-if="item.create_date">
                              {{ formatDate(item.create_date) }}
                            </span>
                            <span v-else>NA</span>
                          </td>
                          <td>
                            <v-dialog
                              v-model="item.dialog"
                              persistent
                              max-width="590"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-bind="attrs"
                                  v-on="on"
                                  style="cursor: pointer; color: #766bc0"
                                  >mdi-eye</v-icon
                                >
                              </template>
                              <v-card>
                                <v-layout wrap>
                                  <v-flex xs12 pa-4>
                                    <v-layout wrap>
                                      <v-flex xs12 sm6 text-sm-left>
                                        <span
                                          class="nsbold"
                                          style="font-size: 16px"
                                        >
                                        Feedback
                                        </span>
                                      </v-flex>
                                    </v-layout>
                                    <v-divider></v-divider>
                                    <v-layout wrap pa-md-4>
                                      <v-flex xs12 md6 text-md-left>
                                        <span
                                          class="nsregular"
                                          style="font-size: 14px"
                                        >
                                        {{ item.feedback }}
                                        </span>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>

                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    color="#766BC0"
                                    text
                                    @click="item.dialog = false"
                                  >
                                    Close
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-flex>
                <v-flex v-else xs12 align-self-center text-center pa-2>
                  <span class="nsregular">Currently no item found!</span>
                </v-flex>
              </v-layout>
              <v-pagination
                small
                color="#766BC0"
                total-visible="7"
                v-model="page"
                :length="Pagelength"
              >
              </v-pagination>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      page: 1,
      Pagelength: 0,
      keyword: null,
      msg: null,
      showsnackbar: false,
      ServerError: false,
      appLoading: false,
      feedbackData: null,
    };
  },
  mounted() {
    this.getData();
  },
  watch: {
    page() {
      // this.getData();
      this.getData();
    },
    keyword() {
      if(this.page>1)
      this.page=1
      // this.getData();
      this.getData();
    },
  },
  methods: {
    getData() {
      this.appLoading = true;
       axios({
        method: "get",
        url: "/feedback/getlist",

        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.$route.query.id,
          page: this.page,
          limit: 20,
          keyword: this.keyword,
         
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.feedbackData = response.data.data;
            this.Pagelength = Math.ceil(response.data.totalLength / 20);
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " , " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;

      return strTime;
    },
  },
};
</script>
